import { inject }                    from 'aurelia-framework';
import { PLATFORM }                  from 'aurelia-pal';
import { BaseFormViewModel }         from 'base-form-view-model';
import { FormSchema }                from 'modules/map/mat-app-proposals/form-schema';
import { MatAppProposalsRepository } from 'modules/map/mat-app-proposals/services/repository';
import { AppContainer }              from 'resources/services/app-container';

@inject(AppContainer, MatAppProposalsRepository, FormSchema)
export class CreateMatAppProposal extends BaseFormViewModel {

    headerTitle = 'form.title.create-new-record';
    formId      = 'map.mat-app-proposals.creation-form';

    alert  = {};
    model  = {};
    schema = {};

    create = true;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param formSchema
     */
    constructor(appContainer, repository, formSchema) {
        super(appContainer);

        this.create     = true;
        this.repository = repository;
        this.formSchema = formSchema;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('modules/map/mat-app-proposals/form.html');
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return this.redirectToSelectLotIntervention('map.mat-app-proposals.index') && super.can([
            'map.mat-app-proposals.manage',
            'map.mat-app-proposals.create',
        ]);
    }

    /**
     * Handles activate event
     */
    async activate(params) {
        super.activate();

        this.nextOrderNumber = await this.fetchNextOrderNumber();

        this.schema       = this.formSchema.schema(this);
        this.globalSchema = this.formSchema.globalSchema(this);
        this.initialModel = this.formSchema.model(this.nextOrderNumber);
        this.model        = this.formSchema.model(this.nextOrderNumber);

        if (params.duplicatingId) {
            const duplicatingModel = await this.repository.find(params.duplicatingId);

            this.initialModel.assign(duplicatingModel);
            this.model.assign(duplicatingModel);
        }
    }

    /**
     * Fetches the next order number
     *
     * @returns {Promise}
     */
    async fetchNextOrderNumber() {
        return await this.repository.fetchNextOrderNumber();
    }

    handleTabClick() {
        if (this.create) {
            this.alert = this.warningAlertMessage(this.appContainer.i18n.tr('form.message.please-save-record-before-attaching'));
        }
    }

    /**
     * Submits view form
     *
     * @returns {Promise}
     */
    async submit() {
        this.alert = null;

        return await this.repository.create(this.model)
            .then((response) => {
                // if success in creating, redirect to edit page
                this.handleResponse(response,
                    (response.status === true)
                        ? {
                            name:   'map.mat-app-proposals.edit',
                            params: { id: response.model.id },
                        }
                        : null);

                this.initialModel.assign(this.model);
            })
            .catch((error) => console.error(error));
    }

}
