import { DialogService }             from 'aurelia-dialog';
import { bindable, inject }          from 'aurelia-framework';
import { I18N }                      from 'aurelia-i18n';
import { PLATFORM }                  from 'aurelia-pal';
import { BaseListViewModel }         from 'base-list-view-model';
import { BooleanOption }             from 'modules/administration/models/boolean-option';
import { ReclassifyManufacturers }   from 'modules/map/management/manufacturers/reclassify';
import { ReclassifySuppliers }       from 'modules/map/management/suppliers/reclassify';
import { FilterFormSchema }          from 'modules/map/mat-app-proposals/filter-form-schema';
import { InfoDialog }                from 'resources/elements/html-elements/dialogs/info-dialog';
import { AppContainer }              from 'resources/services/app-container';
import { Downloader }                from 'resources/services/downloader';
import { MatAppProposalsRepository } from './services/repository';

@inject(AppContainer, MatAppProposalsRepository, I18N, Downloader, FilterFormSchema, DialogService)
export class ListMatAppProposals extends BaseListViewModel {

    listingId = 'mat-app-proposals-listing';

    @bindable headerTitle    = 'listing.map.mat-app-proposals';
    @bindable newRecordRoute = 'map.mat-app-proposals.create';
    @bindable repository;
    @bindable datatable;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param i18N
     * @param downloader
     * @param filterFormSchema
     * @param dialogService
     */
    constructor(appContainer, repository, i18N, downloader, filterFormSchema, dialogService) {
        super(appContainer);

        this.repository       = repository;
        this.i18N             = i18N;
        this.downloader       = downloader;
        this.filterFormSchema = filterFormSchema;
        this.dialogService    = dialogService;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return this.appContainer.globalConfig.defaultListView;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return this.redirectToSelectLotIntervention('map.mat-app-proposals.index') && super.can([
            'map.mat-app-proposals.manage',
            'map.mat-app-proposals.view',
        ]);
    }

    /**
     * Handles activate event
     */
    async activate() {
        super.activate();

        this.defineDatatable();

        this.filterSchema = this.filterFormSchema.schema(this);
        this.filterModel  = this.filterFormSchema.model(this);
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository:         this.repository,
            show:               {
                action:  (row) => this.appContainer.router.navigateToRoute('map.mat-app-proposals.view', { id: row.id }),
                visible: (row) => this.appContainer.authenticatedUser.can(['map.mat-app-proposals.manage', 'map.mat-app-proposals.view']),
            },
            edit:               {
                action:  (row) => this.appContainer.router.navigateToRoute('map.mat-app-proposals.edit', { id: row.id }),
                visible: (row) => this.appContainer.authenticatedUser.can(['map.mat-app-proposals.manage', 'map.mat-app-proposals.edit']),
            },
            destroy:            {
                action:  (row) => this.repository.destroy(row.id),
                visible: (row) => this.appContainer.authenticatedUser.can(['map.mat-app-proposals.manage', 'map.mat-app-proposals.delete']),
            },
            actionsContextMenu: true,
            options:            [
                {
                    label:   'form.button.export-to-excel',
                    icon:    'icon-file-excel',
                    action:  () => this.exportListing(this.i18N.tr('form.field.mat-app-proposals'), !this.datatable.instance.selectedRows.length ? null : this.datatable.instance.selectedRows),
                    visible: (row) => this.appContainer.authenticatedUser.can(['map.mat-app-proposals.manage', 'map.mat-app-proposals.view']),
                },
                {
                    label:   'form.button.reclassify-manufacturers',
                    icon:    'icon-office',
                    action:  () => this.viewReclassify('manufacturer'),
                    visible: () => this.appContainer.authenticatedUser.can(['map.mat-app-proposals.manage', 'map.mat-app-proposals.edit']),
                },
                {
                    label:   'form.button.reclassify-suppliers',
                    icon:    'icon-truck',
                    action:  () => this.viewReclassify('supplier'),
                    visible: () => this.appContainer.authenticatedUser.can(['map.mat-app-proposals.manage', 'map.mat-app-proposals.edit']),
                },
                {
                    label:   'form.button.close-selected',
                    icon:    'icon-cross',
                    action:  () => this.ensureSelectedRows(() => this.repository.closeSelected(this.datatable.instance.selectedRows).then((response) => {
                        this.appContainer.notifier.responseNotice(response);
                        this.datatable.instance.reload();
                    })),
                    visible: () => this.appContainer.authenticatedUser.can(['map.mat-app-proposals.manage', 'map.mat-app-proposals.edit']),
                },
                {
                    label:   'form.button.reopen-selected',
                    icon:    'icon-checkmark3',
                    action:  () => this.ensureSelectedRows(() => this.repository.reopenSelected(this.datatable.instance.selectedRows).then((response) => {
                        this.appContainer.notifier.responseNotice(response);
                        this.datatable.instance.reload();
                    })),
                    visible: () => this.appContainer.authenticatedUser.can(['map.mat-app-proposals.manage', 'map.mat-app-proposals.edit']),
                },
            ],
            actions:            [
                {
                    icon:    'icon-copy3',
                    action:  (row) => this.appContainer.router.navigateToRoute('map.mat-app-proposals.create', { duplicatingId: row.id }),
                    tooltip: 'form.button.duplicate',
                    visible: (row) => this.appContainer.authenticatedUser.can(['map.mat-app-proposals.manage', 'map.mat-app-proposals.create']),
                },
                {
                    icon:    'icon-checkmark3',
                    action:  (row) => this.reopen(row),
                    tooltip: 'form.button.reopen',
                    visible: (row) => this.appContainer.authenticatedUser.can(['map.mat-app-proposals.manage', 'map.mat-app-proposals.edit']) && row.validation_status.id === BooleanOption.YES,
                },
                {
                    icon:    'icon-cross',
                    action:  (row) => this.close(row),
                    tooltip: 'form.button.close',
                    visible: (row) => this.appContainer.authenticatedUser.can(['map.mat-app-proposals.manage', 'map.mat-app-proposals.edit']) && row.validation_status.id === BooleanOption.NO,
                },
            ],
            selectable:         true,
            destroySelected:    true,
            sorting:            {
                column:    0,
                direction: 'asc',
            },
            columns:            [
                //{ TODO
                //    data:  'concession_name',
                //    name:  'concession_translations.name',
                //    title: 'form.field.concession',
                //},
                {
                    data:  'entity_name',
                    name:  'entity_translations.name',
                    title: 'form.field.entity',
                },
                {
                    data:  'created_by',
                    name:  'users.name',
                    title: 'form.field.created-by',
                    type:  'userEntityTeamLot',
                },
                {
                    data:            'created_at',
                    name:            'mat-app-proposals.created_at',
                    title:           'form.field.created-at',
                    valueConverters: [
                        {
                            name: 'dateTimeFormat',
                        },
                    ],
                },
                {
                    data:  'lot',
                    name:  'mat_app_proposals.lot',
                    title: 'form.field.lot',
                },
                {
                    data:  'internal_order',
                    name:  'mat_app_proposals.internal_order',
                    title: 'form.field.internal-order',
                },
                {
                    data:  'map_number',
                    name:  'mat_app_proposals.map_number',
                    title: 'form.field.map-number',
                },
                {
                    data:       'categories',
                    name:       'mat-app-proposals.categories',
                    title:      'form.field.categories',
                    searchable: false,
                    orderable:  false,
                    type:       'processed',
                    processor:  (row) => this.joinArrayInList(row, 'categories'),
                },
                {
                    data:  'material_type_description',
                    name:  'mat_app_proposals.material_type_description',
                    title: 'form.field.description',
                },
                {
                    data:       'applications',
                    name:       'mat-app-proposals.applications',
                    title:      'form.field.applications',
                    searchable: false,
                    orderable:  false,
                    type:       'processed',
                    processor:  (row) => this.joinArrayInList(row, 'applications'),
                },
                {
                    data:  'use_locations',
                    name:  'mat_app_proposals.use_locations',
                    title: 'form.field.use-locations',
                },
                {
                    data:  'supplier_name',
                    name:  'supplier_translations.name',
                    title: 'form.field.supplier',
                },
                {
                    data:  'manufacturer_name',
                    name:  'manufacturer_translations.name',
                    title: 'form.field.manufacturer',
                },
                {
                    data:  'opinion',
                    name:  'mat_app_proposals.opinion',
                    title: 'form.field.opinion',
                },
                {
                    data:       'validation_status',
                    name:       'validation_status',
                    title:      'form.field.validated',
                    type:       'custom-cell',
                    viewModel:  PLATFORM.moduleName('modules/map/mat-app-proposals/custom-listing-cells/validated/index'),
                    orderable:  false,
                    searchable: false,
                },
            ],
        };
    }

    /**
     * Make list of allowed users
     *
     * @param row
     * @param column
     * @returns {string}
     */
    joinArrayInList(row, column) {
        let html = '<ul>';

        for (let item of row[column]) {
            html += '<li>';
            html += item;
            html += '</li>';
        }

        html += '</ul>';
        return html;
    }

    /**
     * Opens the application setting modal
     *
     */
    viewReclassify(type = 'manufacturer') {
        this.dialogService
            .open({
                viewModel: (type === 'manufacturer') ? ReclassifyManufacturers : ReclassifySuppliers,
            })
            .whenClosed((response) => this.datatable.instance.reload());
    }

    /**
     * Executes a given action if it is selected at least one row
     *
     * @param action
     * @returns {*}
     */
    ensureSelectedRows(action) {
        if (!this.datatable.instance.selectedRows.length) {
            return this.dialogService.open({
                viewModel: InfoDialog,
                model:     {
                    body:  this.appContainer.i18n.tr('message.select-at-least-one-record'),
                    title: this.appContainer.i18n.tr('text.attention'),
                },
            });
        }

        action();
    }

    /**
     * Closes a given record
     *
     * @param row
     */
    close(row) {
        this.repository
            .close(row.id)
            .then(response => {
                this.appContainer.notifier.responseNotice(response);
                this.datatable.instance.reload();
            });
    }

    /**
     * Reopens a given record
     *
     * @param row
     */
    reopen(row) {
        this.repository
            .reopen(row.id)
            .then(response => {
                this.appContainer.notifier.responseNotice(response);
                this.datatable.instance.reload();
            });
    }

}
