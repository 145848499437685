import { inject }                    from 'aurelia-framework';
import { EntitiesRepository }        from 'modules/management/concessions/entities/services/repository';
import { TeamsRepository }           from 'modules/management/concessions/teams/services/repository';
import { MAPApplicationsRepository } from 'modules/map/management/applications/services/repository';
import { MAPCategoriesRepository }   from 'modules/map/management/categories/services/repository';
import { ManufacturersRepository }   from 'modules/map/management/manufacturers/services/repository';
import { SuppliersRepository }       from 'modules/map/management/suppliers/services/repository';
import { MAPFilesRepository }        from 'modules/map/mat-app-proposals/services/files-repository';
import { MatAppProposal }            from 'modules/map/models/mat-app-proposal';

@inject(EntitiesRepository, TeamsRepository, MAPCategoriesRepository, MAPApplicationsRepository, SuppliersRepository, ManufacturersRepository, MAPFilesRepository)
export class FormSchema {

    /**
     * Model default values
     */
    modelDefaults = {
        validation_status: 0,
    };

    /**
     * Constructor
     *
     * @param entitiesRepository
     * @param teamsRepository
     * @param categoriesRepository
     * @param applicationsRepository
     * @param suppliersRepository
     * @param manufacturersRepository
     */
    constructor(entitiesRepository, teamsRepository, categoriesRepository, applicationsRepository, suppliersRepository, manufacturersRepository) {
        this.entitiesRepository      = entitiesRepository;
        this.teamsRepository         = teamsRepository;
        this.categoriesRepository    = categoriesRepository;
        this.applicationsRepository  = applicationsRepository;
        this.suppliersRepository     = suppliersRepository;
        this.manufacturersRepository = manufacturersRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {MatAppProposal}
     */
    model(nextOrderNumber = null) {
        let model = new MatAppProposal();

        if (nextOrderNumber) {
            model.internal_order = nextOrderNumber;
        }

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @param readonly
     * @returns {*[]}
     */
    schema(viewModel, readonly = false) {

        //TODO: fill concession data automatically from selected lot/intervention
        this.concession = {
            type:       'text',
            key:        'concession',
            label:      'form.field.concession',
            size:       4,
            attributes: {
                readonly: readonly,
            },
        };

        this.highway = {
            type:       'text',
            key:        'highway',
            label:      'form.field.highway',
            size:       4,
            attributes: {
                readonly: readonly,
            },
        };

        this.phase = {
            type:       'text',
            key:        'phase',
            label:      'form.field.phase',
            size:       4,
            attributes: {
                readonly: readonly,
            },
        };

        this.lot = {
            type:       'text',
            key:        'lot',
            label:      'form.field.lot',
            size:       4,
            attributes: {
                readonly: readonly,
            },
        };

        this.internal_order = {
            type:       'number',
            key:        'internal_order',
            label:      'form.field.internal-order',
            size:       4,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.map_number = {
            type:       'text',
            key:        'map_number',
            label:      'form.field.map-number',
            size:       4,
            attributes: {
                readonly: readonly,
            },
        };

        this.entity_id = {
            type:         'select2',
            key:          'entity_id',
            label:        'form.field.entity',
            remoteSource: () => this.entitiesRepository.all(),
            observers:    [
                (newValue) => {
                    this.team_id.instance.disable(!newValue);

                    if (newValue) {
                        return this.teamsRepository.searchMap({ entities_ids: [newValue] }).then((response) => {
                            this.team_id.options = response;
                            this.team_id.instance.enable(response.length);
                        });
                    }
                },
            ],
            required:     false,
            size:         4,
            attributes:   {
                readonly: readonly,
            },
        };

        this.team_id = {
            type:         'select2',
            key:          'team_id',
            label:        'form.field.team',
            remoteSource: () => {
                return viewModel.model.entity_id
                    ? this.teamsRepository.searchMap({ entities_ids: [viewModel.model.entity_id] })
                    : Promise.resolve([]);
            },
            required:     false,
            size:         4,
            attributes:   {
                disabled: !viewModel.model.team_id,
            },
        };

        this.map_reference = {
            type:       'text',
            key:        'map_reference',
            label:      'form.field.map-reference',
            required:   false,
            size:       4,
            attributes: {
                readonly: readonly,
            },
        };

        this.map_date = {
            type:       'material-ui-date-picker',
            key:        'map_date',
            label:      'form.field.map-date',
            size:       4,
            attributes: {
                readonly: readonly,
            },
        };

        this.approval_date = {
            type:       'material-ui-date-picker',
            key:        'approval_date',
            label:      'form.field.approval-date',
            required:   false,
            size:       4,
            attributes: {
                readonly: readonly,
            },
        };

        this.categories = {
            type:         'duallistbox',
            key:          'categories',
            label:        'form.field.categories',
            size:         12,
            remoteSource: () => this.categoriesRepository.active(),
            attributes:   {
                readonly: readonly,
            },
        };

        this.material_type_description = {
            type:       'textarea',
            key:        'material_type_description',
            label:      'form.field.material-type-description',
            size:       6,
            attributes: {
                readonly: readonly,
            },
        };

        this.commercial_name = {
            type:       'textarea',
            key:        'commercial_name',
            label:      'form.field.commercial-name',
            required:   false,
            size:       6,
            attributes: {
                readonly: readonly,
            },
        };

        this.applications = {
            type:         'duallistbox',
            key:          'applications',
            label:        'form.field.applications',
            size:         12,
            remoteSource: () => this.applicationsRepository.active(),
            attributes:   {
                readonly: readonly,
            },
        };

        this.application_details = {
            type:       'textarea',
            key:        'application_details',
            label:      'form.field.application-details',
            size:       12,
            required:   false,
            attributes: {
                readonly: readonly,
            },
        };

        this.quantity = {
            type:       'text',
            key:        'quantity',
            label:      'form.field.quantity',
            size:       6,
            required:   false,
            attributes: {
                readonly: readonly,
            },
        };

        this.use_locations = {
            type:       'text',
            key:        'use_locations',
            label:      'form.field.use-locations',
            size:       6,
            required:   false,
            attributes: {
                readonly: readonly,
            },
        };

        this.manufacturer_id = {
            type:         'select2',
            key:          'manufacturer_id',
            label:        'form.field.manufacturer',
            remoteSource: () => this.manufacturersRepository.all(),
            size:         6,
            required:     false,
            attributes:   {
                readonly: readonly,
            },
        };

        this.other_manufacturer = {
            type:       'text',
            key:        'other_manufacturer',
            label:      'form.field.other-manufacturer',
            size:       6,
            required:   false,
            attributes: {
                readonly: readonly,
            },
        };

        this.supplier_id = {
            type:         'select2',
            key:          'supplier_id',
            label:        'form.field.supplier',
            remoteSource: () => this.suppliersRepository.all(),
            size:         6,
            required:     false,
            attributes:   {
                readonly: readonly,
            },
        };

        this.other_supplier = {
            type:       'text',
            key:        'other_supplier',
            label:      'form.field.other-supplier',
            size:       6,
            required:   false,
            attributes: {
                readonly: readonly,
            },
        };

        this.material_guarantee_period = {
            type:       'text',
            key:        'material_guarantee_period',
            label:      'form.field.material-guarantee-period',
            size:       6,
            required:   false,
            attributes: {
                readonly: readonly,
            },
        };

        this.opinion = {
            type:       'textarea',
            key:        'opinion',
            label:      'form.field.opinion',
            size:       6,
            required:   false,
            attributes: {
                readonly: readonly,
            },
        };

        this.observations = {
            type:       'textarea',
            key:        'observations',
            label:      'form.field.observations',
            size:       12,
            required:   false,
            attributes: {
                readonly: readonly,
            },
        };

        const schema = [
            [this.concession, this.highway, this.phase],
            [this.lot, this.internal_order, this.map_number],
            [this.entity_id, this.team_id, this.map_reference],
            [this.map_date, this.approval_date],
            [this.categories],
            [this.material_type_description, this.commercial_name],
            [this.applications],
            [this.application_details],
            [this.quantity, this.use_locations],
            [this.manufacturer_id, this.other_manufacturer],
            [this.supplier_id, this.other_supplier],
            [this.material_guarantee_period, this.opinion],
            [this.observations],
        ];

        if (readonly) {
            this.created_by = {
                type:       'text',
                key:        'created_by',
                label:      'form.field.created-by',
                size:       6,
                attributes: {
                    readonly: true,
                },
            };

            this.created_at = {
                type:       'text',
                key:        'created_at',
                label:      'form.field.created-at',
                size:       6,
                attributes: {
                    readonly: true,
                },
            };

            return [...schema, [this.created_by, this.created_at]];
        }

        return schema;
    }

    /**
     * Returns buttons schema
     *
     * @param viewModel
     * @param readonly
     *
     * @returns {*[]}
     */
    globalSchema(viewModel, readonly = false) {
        this.backButton = {
            type:       'button',
            label:      viewModel.modal ? 'form.button.cancel' : 'form.button.back',
            action:     () => viewModel.modal ? viewModel.dialogController.cancel() : viewModel.redirectToRoute('map.mat-app-proposals.index'),
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: viewModel.modal ? 'icon-close2' : 'icon-chevron-left',
                },
            },
        };

        this.clearButton = {
            type:       'button',
            label:      viewModel.create ? 'form.button.clear' : 'form.button.reverse-changes',
            action:     () => viewModel.resetForm(),
            hidden:     readonly,
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-close2',
                },
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.save',
            action:     () => viewModel.submit(),
            hidden:     readonly,
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                attributes: {
                    class: 'icon-floppy-disk',
                },
            },
        };

        if (!viewModel.create) {
            this.submitButton.action = (event) => {
                if (viewModel.listFiles && viewModel.listFiles.creationSchemaVisible) {
                    return this.showSubmitConfirmationAlert(viewModel);
                }

                return viewModel.submit();
            };
        }

        this.buttons = {
            type:    'buttons',
            actions: [
                this.backButton,
                this.clearButton,
                this.submitButton,
            ],
        };

        return [
            [this.buttons],
        ];
    }

    /**
     * Shows submit confirmation alert
     *
     * @param viewModel
     */
    showSubmitConfirmationAlert(viewModel) {
        let i18n        = viewModel.appContainer.i18n;
        let swalService = viewModel.appContainer.swalService;

        let options = {
            title:              i18n.tr('text.attention'),
            text:               i18n.tr('form.message.submit-without-unsaved-files'),
            type:               'warning',
            showCancelButton:   true,
            cancelButtonText:   i18n.tr('form.button.cancel'),
            confirmButtonColor: '#FF7043',
            confirmButtonText:  i18n.tr('form.button.ok'),
            closeOnConfirm:     true,
        };

        swalService.confirmAlert('form.message.submit-without-unsaved-files', (confirmed) => {
            if (confirmed) {
                viewModel.submit();
            }
        });
    }

}
